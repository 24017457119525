import '@/scss/main.scss';
import Vue from 'vue';
import VueMasonry from 'vue-masonry-css';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.prototype.$http = axios;
Vue.prototype.$apiBase = process.env.VUE_APP_API_BASE_PATH;
Vue.config.productionTip = false;
Vue.use(VueMasonry);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
