import Vue from 'vue';
import VueRouter from 'vue-router';
import MainPage from '../views/MainPage.vue';
import CompanyPage from '../views/CompanyPage.vue';
import TermsPage from '../views/TermsPage.vue';
import PrivacyPage from '../views/PrivacyPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage,
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyPage,
    meta: {
      layout: 'InfoLayout',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage,
    meta: {
      layout: 'InfoLayout',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
    meta: {
      layout: 'InfoLayout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
