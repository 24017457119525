<template>
  <div class="main-page">
    <ProductList />
    <div class="pagination-wrap">
      <PaginationBlock
        v-if="pageCount > 1"
        :page="page"
        :page-count="pageCount"
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductList from '@/components/ProductList.vue';
import PaginationBlock from '@/components/PaginationBlock.vue';

export default {
  components: {
    ProductList,
    PaginationBlock,
  },
  beforeMount() {
    this.fetchStores();
    this.resolveQuery();
  },
  computed: {
    ...mapGetters(['page', 'pageCount', 'isEmptyProductList']),
  },
  methods: {
    ...mapActions(['fetchProductList', 'fetchSingleProduct', 'fetchStores', 'setPage']),
    changePage(page) {
      this.setPage(page);
      this.fetchProductsWithPage(page);
    },
    resolveQuery() {
      if (this.$route.query.id) {
        this.fetchSingleProduct(this.$route.query.id).then(() => {
          if (!this.isEmptyProductList) {
            this.$router.replace({
              name: this.$route.name,
              query: { id: this.$route.query.id },
            });
          }
        });
      } else {
        this.fetchProductsWithPage(this.$route.query.page);
      }
    },
    fetchProductsWithPage(page) {
      this.fetchProductList({
        ...this.$route.query,
        page: page || this.$route.query.page || 1,
      }).then(() => {
        if (this.$route.query.page !== page) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: page || this.$route.query.page || 1,
            },
            name: this.$route.name,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
 .main-page {
   .pagination-wrap {
     margin-bottom: 25px;
   }
 }
</style>
