<template>
  <a
    class="product-card"
    :href="link"
    target="_blank"
    rel="nofollow"
  >
    <div class="image-wrapper">
      <img
        :src="img"
        alt="product-image"
        class="image"
      >
    </div>
    <div class="info">
      <div class="link-wrapper">
        <a
          :href="link"
          target="_blank"
          rel="nofollow"
          class="title"
        >{{ title|limitCharacters(55) }}</a>
      </div>
      <div class="price-wrapper">
        <div
          class="price"
          :class="{ sale: salePrice }"
        >
          {{ salePrice || price }}
        </div>
        <div
          v-if="salePrice"
          class="price old"
        >
          {{ price }}
        </div>
      </div>
      <div class="store">
        {{ storeName|limitCharacters(25) }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    title: String,
    img: String,
    link: String,
    storeName: String,
    price: [String, Number],
    salePrice: [String, Number],
    currency: String,
  },
  filters: {
    siteFromUrl(url) {
      return url.split('/')[2];
    },
    limitCharacters(string, limit) {
      return string.length <= limit
        ? string
        : `${string.slice(0, limit - 3)}...`;
    },
  },
};
</script>

<style lang="scss">
.product-card {
  display: block;
  cursor: pointer;
  border-radius: $basic-border-radius;
  box-shadow: $basic-shadow;
  transition: box-shadow .2s;
  color: currentColor;

  &:hover {
    box-shadow: $darker-shadow;
  }
  &:hover,
  &:focus,
  &:link,
  &:visited,
  &:active {
    color: currentColor;
    text-decoration: none;
  }

  .image {
    display: block;
    border-radius: $basic-border-radius $basic-border-radius 0 0;
    width: 100%;
  }

  .info {
    padding: 15px;
  }

  .store {
    font-size: 16px;
    margin-bottom: 10px;
    color: $color-brand-secondary;
  }

  .link-wrapper {
    margin-bottom: 10px;
    min-height: 36px;
  }

  .title {
    margin-bottom: 10px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .price-wrapper {
    min-height: 36px;
    margin-bottom: 10px;
  }

  .price {
    &.sale {
      color: $color-red;
    }
    &.old {
      color: $color-neutral-30;
      text-decoration: line-through;
    }
  }
}
</style>
