<template>
  <Paginate
    v-model="value"
    :page-count="pageCount"
    :click-handler="change"
    :page-range="isMobile ? 3 : 5"
    prev-text="prev"
    next-text="next"
    :no-li-surround="true"
    container-class="pagination"
    page-link-class="page-link"
    next-link-class="next"
    prev-link-class="prev"
    break-view-link-class="break-view-link"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from 'vuejs-paginate';

export default {
  props: {
    page: Number,
    pageCount: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Paginate,
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  beforeMount() {
    this.value = this.page;
  },
  watch: {
    page() {
      this.value = this.page;
    },
  },
  data: () => ({
    value: 0,
  }),
  methods: {
    change(pageNumber) {
      this.$emit('change', pageNumber);
    },
  },
};
</script>

<style lang="scss">
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;

    .page-link,
    .next,
    .prev {
      display: block;
      border: $basic-border;
      border-radius: $basic-border-radius;
      padding: 8px;
      margin-right: 8px;
      font-weight: bold;
      font-size: 12px;
      line-height: 1.33;
      text-transform: capitalize;
      transition: opacity .2s, background-color .2s;
      background-color: $color-white;
      &.active {
        background-color: $color-brand-50;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
      &:hover {
        opacity: .8;
      }
    }

    .break-view-link {
      border: none;
      padding: 8px 4px;
      background-color: transparent;
    }
  }
</style>
