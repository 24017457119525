import Vue from 'vue';
import Vuex from 'vuex';

const NUMBER_PRODUCTS_ON_PAGE = 20;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    productList: [],
    stores: [],
    searchRequest: '',
    isProductLoading: false,
    page: 1,
    isMobile: false,
  },
  getters: {
    isMobile: state => state.isMobile,
    productList: state => state.productList.list,
    isEmptyProductList: state => !state.productList.list.length,
    pageCount: state => state.productList.pageCount,
    offset: state => state.productList.offset,
    stores: state => state.stores,
    page: state => state.page,
    searchRequest: state => state.searchRequest,
    isProductLoading: state => state.isProductLoading,
  },
  mutations: {
    setProductList(state, list) {
      state.productList = list;
    },
    setStores(state, stores) {
      state.stores = stores;
    },
    setSearchRequest(state, list) {
      state.searchRequest = list;
    },
    setProductLoading(state, status) {
      state.isProductLoading = status;
    },
    setPage(state, page) {
      state.page = page;
    },
    setIsMobile(state, status) {
      state.isMobile = status;
    },
  },
  actions: {
    fetchSingleProduct({ commit }, id) {
      return this._vm.$http.get(`${this._vm.$apiBase}product/${id}`).then(({ data }) => {
        commit('setProductList', {
          page: 0,
          pageCount: 0,
          list: [data],
        });

        commit('setPage', 1);
        commit('setProductLoading', false);
      });
    },
    fetchProductList({ commit, dispatch }, {
      store,
      search,
      sorting = 'relevance',
      ...otherQuery
    }) {
      const sortingArr = sorting.split('_');
      const sort = sorting === 'relevance' ? sorting : sortingArr[0];
      const direction = sortingArr[1]
        ? sortingArr[2] === 'low'
          ? 'asc'
          : 'desc'
        : null;

      commit('setProductLoading', true);

      this._vm.$http.get(
        `${this._vm.$apiBase}search`,
        {
          params: {
            ...otherQuery,
            q: search || null,
            pageSize: NUMBER_PRODUCTS_ON_PAGE,
            shops: store
              ? store === 'all'
                ? null
                : [store]
              : null,
            sort,
            direction,
          },
        },
      )
        .then(({ data }) => {
          commit('setProductList', {
            page: data.stats.currentPage,
            pageCount: data.stats.numberOfPages,
            list: data.results,
          });
          commit('setPage', data.stats.currentPage);
          if (search && search.length) {
            dispatch('fetchFilteredStores', { search });
          } else {
            dispatch('fetchStores');
          }
          commit('setProductLoading', false);
        });
    },
    fetchStores({ commit }) {
      this._vm.$http.get(`${this._vm.$apiBase}store/all`).then(({ data }) => {
        commit('setStores', data);
      });
    },
    fetchFilteredStores({ commit }, {
      search,
      ...otherQuery
    }) {
      this._vm.$http.get(
        `${this._vm.$apiBase}store/search`,
        {
          params: {
            ...otherQuery,
            q: search || null,
          },
        },
      ).then(({ data }) => {
        commit('setStores', data);
      });
    },
    setSearchRequest({ commit }, searchRequest) {
      commit('setSearchRequest', searchRequest);
    },
    setPage({ commit }, page) {
      commit('setPage', page);
    },
    switchIsMobile({ commit }, status) {
      commit('setIsMobile', status);
    },
  },
});
