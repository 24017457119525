<template>
  <div class="info-layout">
    <div class="wrapper">
      <HeaderBlock clean />
      <main class="main">
        <div class="g-container">
          <slot />
        </div>
      </main>
      <div class="push"></div>
    </div>
    <FooterBlock  class="footer" />
  </div>
</template>

<script>
import HeaderBlock from '@/components/HeaderBlock.vue';
import FooterBlock from '@/components/FooterBlock.vue';

export default {
  components: {
    HeaderBlock,
    FooterBlock,
  },
};
</script>

<style lang="scss">
.info-layout {
  min-height: 100vh;

  .wrapper {
    min-height: 100vh;
    margin-bottom: -150px;
  }

  .footer,
  .push {
    min-height: 150px;
  }
}
</style>
