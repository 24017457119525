<template>
  <div id="app">
    <transition
      name="fade"
      mode="out-in"
    >
      <component :is="layout" :name="layout">
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view />
        </transition>
      </component>
    </transition>
  </div>
</template>

<script>
import DefaultLayout from '@/components/layouts/DefaultLayout.vue';
import InfoLayout from '@/components/layouts/InfoLayout.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    DefaultLayout,
    InfoLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout';
    },
  },
  mounted() {
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  methods: {
    ...mapActions(['switchIsMobile']),
    checkIsMobile() {
      this.switchIsMobile(window.matchMedia('(max-width: 640px)').matches);
    },
  },
};
</script>
