<template>
  <div class="product-list">
    <div class="inner">
      <transition name="fade">
        <div
          v-if="isProductLoading"
          class="loading"
        ></div>
      </transition>
      <masonry
        :cols="{ default: 4, 1000: 3, 760: 2, 520: 1 }"
        :gutter="{ default: '20px' }"
      >
        <ProductCard
          class="card"
          v-for="item in productList"
          :key="item.id"
          :title="item.title"
          :img="item.imageLink"
          :link="item.displayAdLink"
          :price="item.price"
          :sale-price="item.salePrice"
          :store-name="item.storeName"
        />
      </masonry>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  computed: {
    ...mapGetters([
      'productList',
      'isProductLoading',
      'page',
      'pageCount',
    ]),
  },
};
</script>

<style lang="scss">
.product-list {
  padding-top: 30px;

  .inner {
    position: relative;
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .3);
  }

  .card {
    margin-bottom: 20px;
  }

  .pagination-wrap {
    margin-bottom: 25px;
  }
}

@media (max-width: $tablet-width) {
  .product-list {
    .inner {
      width: 718px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 760px) {
  .product-list {
    .inner {
      width: 472px;
    }
  }
}

@media (max-width: 520px) {
  .product-list {
    .inner {
      width: 226px;
    }
  }
}
</style>
